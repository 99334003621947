import './elements/ew-colexp.js';
import './elements/ew-collapse.js';
import './elements/ew-werte.js';
import './elements/ew-team.js';
import './elements/ew-lightbox.js';

document.addEventListener('active-collapse', e => {
  const targetLabel = e.target.querySelector('[slot="title"]');
  if (!targetLabel) return;

  const targetLabelText = targetLabel.textContent.trim();

  const projectGallery = document.querySelector('#projekte-bu');
  if (!projectGallery) return;

  const links = Array.from(projectGallery.querySelectorAll('a'));
  const link = links.find(link => {
    const label = link.querySelector('.inner_sort_button span');
    if (!label) return;
    return label.textContent.trim() === targetLabelText;
  });

  if (!link) return;

  link.click();
});

document.addEventListener('DOMContentLoaded', () => {
  const projectMenu = document.querySelector('#menu-item-1008');

  if (projectMenu) {
    projectMenu.addEventListener('click', () => {
      const link = document.querySelector('[data-filter="all_sort"]');

      if (link) {
        link.click();

        document.querySelectorAll('[group="projectmenu"]').forEach(item => item.close());
      }
    });
  }

  const lb = document.createElement('ew-lightbox');
  document.body.appendChild(lb);
})

document.addEventListener('click', e => {
  const el = e.target;
  
  if (el.classList.contains('job-title')) {
    e.preventDefault();
    el.classList.remove('lightbox-added');
    document.querySelector('ew-lightbox').show(el.href);
  }

}, true)

