import { svg } from 'lit-html/lit-html.js';

export default {
  'triangle': svg`
    <path d="m3.65 2.432h16.7l-8.4 16.6-8.3-16.6" clip-rule="evenodd" fill="var(--fill-color)" fill-rule="evenodd"/><path d="m21.946 1.432-10 19.9-9.9-19.9z" fill="none" stroke="#666d7b" stroke-miterlimit="10" stroke-width="2"/>
  `,
  'phone': svg`
    <path clip-rule="evenodd" fill="#666d7b" fill-rule="evenodd" d="m13.45 21.7h-0.4c-2.7 0-4.6-1.7-4.6-4.1v-11.2c0-2.4 1.9-4.1 4.6-4.1h0.4c1.1 0 2.1 0.9 2.1 2.1v1.9c0 1.1-0.9 2.1-2.1 2.1h-0.6c-0.2 0-0.4 0.2-0.4 0.4v6.3c0 0.2 0.2 0.4 0.4 0.4h0.6c1.1 0 2.1 0.9 2.1 2.1v1.9c-0.1 1.2-1 2.2-2.1 2.2m-0.4-18.4c-1.9 0-3.7 1-3.7 3.2v11.1c0 2.2 1.9 3.2 3.7 3.2h0.4c0.6 0 1.2-0.5 1.2-1.2v-1.9c0-0.6-0.5-1.2-1.2-1.2h-0.6c-0.7 0-1.3-0.6-1.3-1.3v-6.3c0-0.7 0.6-1.3 1.3-1.3h0.6c0.6 0 1.2-0.5 1.2-1.2v-1.9c0-0.6-0.5-1.2-1.2-1.2z"/>
  `,
  'mobile': svg`
    <path d="m10.7 4.2c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5h2.7c0.3 0 0.5 0.2 0.5 0.5s-0.2 0.5-0.5 0.5h-2.7m6.8-2.7c0.3 0 0.5 0.2 0.5 0.5v20c0 0.3-0.2 0.5-0.5 0.5h-11c-0.3 0-0.5-0.2-0.5-0.5v-20c0-0.3 0.2-0.5 0.5-0.5h11m-0.5 4.3h-9.9v11.3h9.9zm-9.9-1h9.9v-2.3h-9.9zm9.9 13.2h-9.9v3.5h9.9zm-4.9 0.6c0.7 0 1.2 0.5 1.2 1.1s-0.5 1.1-1.2 1.1-1.2-0.5-1.2-1.1 0.5-1.1 1.2-1.1" clip-rule="evenodd" fill="#666d7b" fill-rule="evenodd"/>
  `,
  'email': svg`
    <path clip-rule="evenodd" fill="#666d7b" fill-rule="evenodd" d="m21.2 5.5c0.3 0 0.5 0.2 0.5 0.5v12c0 0.3-0.2 0.5-0.5 0.5h-18.4c-0.3 0-0.5-0.2-0.5-0.5v-12c0-0.3 0.2-0.5 0.5-0.5h18.4m-18 1.5v9.8l7-4.9zm8.8 5 8-5.6h-16zm-0.9 0.5-7.1 5h16l-7.1-5-0.6 0.4c-0.1 0.1-0.2 0.1-0.3 0.1s-0.2 0-0.3-0.1zm2.6-0.5 7 4.9v-9.8z"/>
  `,
  'download': svg`
    <path d="m11.55 16.45-1.8-1.7c-0.2-0.2-0.2-0.5 0-0.6 0.2-0.2 0.5-0.2 0.7 0l1.1 1v-12.2c0-0.3 0.2-0.5 0.5-0.5s0.5 0.2 0.5 0.5v12.2l1.1-1.1c0.2-0.2 0.5-0.2 0.7 0s0.2 0.5 0 0.6l-1.9 1.8c-0.1 0.1-0.2 0.1-0.3 0.1-0.4 0.2-0.5 0.1-0.6-0.1m7 0.3c0.3 0 0.5 0.2 0.5 0.5v3.8c0 0.3-0.2 0.5-0.5 0.5h-13.1c-0.3 0-0.5-0.2-0.5-0.5v-3.8c0-0.3 0.2-0.5 0.5-0.5s0.5 0.2 0.5 0.5v3.4h12.1v-3.4c0-0.3 0.2-0.5 0.5-0.5" clip-rule="evenodd" fill="#666d7b" fill-rule="evenodd"/>
  `
};
